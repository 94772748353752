import React from "react";
import "./post-test.css";
import { Paths } from "../../helpers";
import { useNavigate } from "react-router-dom";

const PostTest = () => {
  const navigate = useNavigate();
  const handleButtonOnClick = () => {
    navigate(Paths.Home);
    window.location.reload();
  };

  return (
    <div className="post-test">
      <div className="post-test-div">
        <h3>
          You test session has now ended! If you have just completed a test, your results will be
          emailed to you shortly or you can review them from the results tab.
        </h3>
        <div className="post-test-button">
          <button onClick={handleButtonOnClick}>Continue</button>
        </div>
      </div>
    </div>
  );
};

export default PostTest;
