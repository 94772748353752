import React, { useState } from "react";
import "./test-results.css";
import ScoreBarChart from "./score-bar-chat";
import SectionBarChart from "./section-bar-chart";
import TimeBarChart from "./time-bar-chart";
import TestHistoryLineChart from "./test-history-line-chart";
import SectionHistoryLineChart from "./section-history";

const TestResults = ({
  userResults,
  globalResults,
  username,
  countryResults,
  country,
  setScrollDown,
}) => {
  const [index, setIndex] = useState(0);
  const metrics = ['Score', 'Sections', 'Time Taken', 'Average History', 'Section History'];
  console.log('HERE: ', userResults);
  const usersBestResult = userResults.reduce((max, item) =>
    item["overall-average"] > max["overall-average"] ? item : max, userResults[0]
  ) ?? [];

  console.log('HERE: ', userResults)

  if (usersBestResult.length === 0) {
    return (
      <div></div>
    )
  }

  const scrollLeft = () => {
    setScrollDown(false);
    if (index > 0) {
      setIndex(index - 1);
      setScrollDown(true);
    }
  }

  const scrollRight = () => {
    setScrollDown(false);
    if (index < 4) {
      setIndex(index + 1);
      setScrollDown(true);
    }
  };

  const userSections = [];
  const globalSections = [];
  const countrySections = [];

  const sections = globalResults.sections ?? [];

  for (const section of sections) {
    globalSections.push(globalResults[`${section}-average`]);
  }

  for (const section of sections) {
    userSections.push(usersBestResult[`${section}-average`]);
  }

  for (const section of sections) {
    countrySections.push(countryResults[`${section}-average`]);
  }

  const userTime = usersBestResult.timeTaken;
  const globalTimeAverage =
    globalResults["sum-of-test-time-taken"] / globalResults["tests-written"];
  const countryTimeAverage =
    countryResults["sum-of-test-time-taken"] / countryResults["tests-written"];

  return (
    <div className="test-results">
      <div className="metric-header-mobile">
        <button className="tests-selector-btn-scroll" onClick={scrollLeft}>&#8249;</button>
        <label>{metrics[index]}</label>
        <button className="tests-selector-btn-scroll" onClick={scrollRight}>&#8250;</button>
      </div>
      <div className="metrics-mobile">
        {
          index === 0 ? (<ScoreBarChart
            userAverage={usersBestResult["overall-average"]}
            globalAverage={globalResults["overall-average"]}
            countryAverage={countryResults["overall-average"]}
            username={username}
            country={country}
          />) : index === 1 ? (<SectionBarChart
            userSections={userSections}
            globalSections={globalSections}
            countrySections={countrySections}
            sections={sections}
            username={username}
            country={country}
          />) : index === 2 ? <TimeBarChart
            userTime={userTime}
            countryTimeAverage={countryTimeAverage}
            globalTimeAverage={globalTimeAverage}
            testLength={globalResults.testLength}
            username={username}
            country={country}
          /> : index === 3 ? <TestHistoryLineChart
            userResults={userResults}
          /> : <SectionHistoryLineChart
            userResults={userResults} sections={globalResults.sections}
          />
        }
      </div>
      <div className="test-score">
        <div className="metrics">
          <div className="left-hand-side-metrics">
            <div className="metric">
              <label className="metric-header-label">Score</label>
              <div className="metric-div">
                <ScoreBarChart
                  userAverage={usersBestResult["overall-average"]}
                  globalAverage={globalResults["overall-average"]}
                  countryAverage={countryResults["overall-average"]}
                  username={username}
                  country={country}
                />
              </div>
            </div>
            <div className="metric">
              <label className="metric-header-label">Time taken</label>
              <div className="metric-div">
                <TimeBarChart
                  userTime={userTime}
                  countryTimeAverage={countryTimeAverage}
                  globalTimeAverage={globalTimeAverage}
                  testLength={globalResults.testLength}
                  username={username}
                  country={country}
                />
              </div>
            </div>
          </div>
          <div className="right-hand-side-metrics">
            <div className="metric">
              <label className="metric-header-label">Sections</label>
              <div className="metric-div">
                <SectionBarChart
                  userSections={userSections}
                  globalSections={globalSections}
                  countrySections={countrySections}
                  sections={sections}
                  username={username}
                  country={country}
                />
              </div>
            </div>
            <div className="right-hand-size-bottom-metrics">
              <div className="metric">
                <label className="metric-header-label">Average History</label>
                <div className="metric-div">
                  <TestHistoryLineChart userResults={userResults} />
                </div>
              </div>
              <div className="metric">
                <label className="metric-header-label">Section History</label>
                <div className="metric-div">
                  <SectionHistoryLineChart userResults={userResults} sections={globalResults.sections} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default TestResults;
