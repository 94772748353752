import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./time-bar-chart.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TimeBarChart = ({
  userTime,
  globalTimeAverage,
  testLength,
  username,
  countryTimeAverage,
  country,
}) => {
  const labels = [username, country, "Global"];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
      },
    },
    scales: {
      y: {
        min: 0,
        max: testLength / 60,
        ticks: {
          font: {
            weight: "bold",
          },
          color: "rgb(91, 88, 88)",
        },
      },
      x: {
        ticks: {
          font: {
            weight: "bold",
            size: "8%"
          },
          color: "rgb(91, 88, 88)",
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: [parseFloat(userTime / 60).toFixed(2), parseFloat(countryTimeAverage / 60).toFixed(2), parseFloat(globalTimeAverage / 60).toFixed(2)],
        backgroundColor: "#7d81e2",
        borderRadius: 5,
        width: 10,
      },
    ],
  };

  return (
    <div className="time-barchart">
      <Bar className="barchart" options={options} data={data} />
    </div>
  );
};

export default TimeBarChart;
