import React, { useState, useRef } from "react";
import "./results.css";
import TestResults from "../components/test-results";
import Leaderboard from "../components/leader-board";
import { useLocation } from "react-router-dom";

const Results = ({ generalTableData, allResults }) => {
  const [scrollDown, setScrollDown] = useState(false);
  const divRef = useRef(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const testId = queryParams.get("testId") ?? 'IBIS100';
  
  const leaderboardResults = allResults[`${testId}-Leaderboard`];

  const country = generalTableData.country;
  const username = generalTableData.username;

  const globalResult = Object.keys(allResults).length !== 0 ? allResults[`${testId}-Graphs`]["globalResult"] : [];
  const countryResult = Object.keys(allResults).length !== 0 ? allResults[`${testId}-Graphs`]["countryResult"] : [];
  
  const allUserResultsLength = Object.keys(allResults).length !== 0 ? allResults[`${testId}-Graphs`]["allUserResults"].length : 0;

  if (scrollDown && divRef.current) {
    requestAnimationFrame(() => {
      divRef.current.scrollTo({
        top: divRef.current.scrollHeight,
        behavior: "smooth",
      });
    });
  }

  return (
    <div className="results">
      <div className="results-components" ref={divRef}>
        <div className="results-background">
          <h1 className="results-title">
            Leaderboard and Completed Test Results
          </h1>
          <Leaderboard results={leaderboardResults} username={username} testId={testId} />
          { allUserResultsLength !== 0 ?
            <TestResults
              className="test-results"
              testId={testId}
              userResults={Object.keys(allResults).length !== 0 ? allResults[`${testId}-Graphs`]["allUserResults"] : []}
              globalResults={globalResult}
              countryResults={countryResult}
              country={country}
              username={username}
              setScrollDown={setScrollDown}
            /> : null
          }
        </div>
      </div>
    </div>
  );
};

export default Results;
