import React from "react";
import { useNavigate } from "react-router-dom";
import "./side-bar.css";
import { FaRegUserCircle } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";
import { Paths } from "../helpers/constants";
import { IoMenu } from "react-icons/io5";

const SideBar = ({ signOut, location, username, mobileSideBarNavigation }) => {
  const navigate = useNavigate();
  const activeLink = `#${location.replace("/", "")}`;

  const handleLinkClick = (event, path) => {
    event.preventDefault();
    navigate(`/${path}`);
  };

  const handleLogoutClick = () => {
    if (signOut) {
      signOut();
    }
  };

  const handleIconOnClick = () => {
    navigate(Paths.Profile);
  };

  const handleLogoOnClick = () => {
    navigate(Paths.Home)
  }

  const showSideBarNavigation = () => {
    navigate(Paths.MobileSideBarNavigation);
  }

  return (
    <div className={mobileSideBarNavigation ? "side-bar-nav" : "side-bar"}>
      <div className={mobileSideBarNavigation ? "side-bar-navigation" : "side-bar-mobile"}>
        <div className="user-icon-div-mobile">
          <FaRegUserCircle className="user-icon-mobile" onClick={handleIconOnClick} />
          <p>{username}</p>
        </div>
        <div className="side-bar-menu-mobile" onClick={showSideBarNavigation}>
          <IoMenu className="menu-mobile" />
        </div>
      </div>
      <div className={mobileSideBarNavigation ? "side-bar-mobile-navigation" : "side-bar-navigation"}>
        <div className="logo-div" onClick={handleLogoOnClick}>
          <h1>IBIS&trade;</h1>
        </div>
        <div className="user-icon-div">
          <FaRegUserCircle className="user-icon" onClick={handleIconOnClick} />
          <p>{username}</p>
        </div>
        <div className="side-bar-nav">
          <ul>
            <li>
              <a
                href="#home"
                onClick={(e) => handleLinkClick(e, "home")}
                className={activeLink === "#home" ? "active" : ""}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#booktest"
                onClick={(e) => handleLinkClick(e, "booktest")}
                className={activeLink === "#booktest" ? "active" : ""}
              >
                Book a Test
              </a>
            </li>
            <li>
              <a
                href="#mytests"
                onClick={(e) => handleLinkClick(e, "mytests")}
                className={activeLink === "#mytests" ? "active" : ""}
              >
                My Tests
              </a>
            </li>
            <li>
              <a
                href="#results"
                onClick={(e) => handleLinkClick(e, "results")}
                className={activeLink === "#results" ? "active" : ""}
              >
                Results
              </a>
            </li>
          </ul>
        </div>
        <div className="logout-div">
          <CiLogout onClick={handleLogoutClick} className="logout" />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
