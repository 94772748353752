import React from "react";
import "./title.css";

const Title = () => {
  return (
    <div className="title">
        <h1>TEST DETAILS</h1>
         <p>*Before booking your test, please read the test information below, carefully.*</p>
    </div>
  );
};

export default Title;
