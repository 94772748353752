import React, { useState, useEffect, useRef } from "react";
import "./leader-board.css";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../helpers/constants";
import { useLocation } from "react-router-dom";

const Tests = ['IBIS100', 'Practice test'];

const Leaderboard = ({ results, username }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const testId = queryParams.get("testId") ?? 'IBIS100';

  const [scrollY, setScrollY] = useState(0);
  const tableBodyRef = useRef(null);
  const [index, setIndex] = useState(testId === 'IBIS100' ? 0 : 1);
  const [testTitle, setTestTitle] = useState(testId);

  const showLeaderboard = results && results.length !== 0;

  useEffect(() => {
    const handleScroll = () => {
      if (tableBodyRef.current) {
        setScrollY(tableBodyRef.current.scrollTop);
      }
    };

    const tableBody = tableBodyRef.current;
    tableBody.addEventListener("scroll", handleScroll);

    return () => {
      tableBody.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fixedIndex = (results ?? []).findIndex(
    (player) => player.username === username
  );
  const isFixedVisible = fixedIndex * 25 > scrollY + 50;

  const scrollLeft = () => {
    if (index > 0) {
      const newIndex = index - 1;
      setIndex(newIndex);
      setTestTitle(Tests[newIndex]);

      const params = new URLSearchParams({
        testId: Tests[newIndex],
      });

      navigate(`${Paths.Results}?${params.toString()}`);
    }
  };

  const scrollRight = () => {
    if (index < Tests.length - 1) {
      const newIndex = index + 1;
      setIndex(newIndex);
      setTestTitle(Tests[newIndex]);

      const params = new URLSearchParams({
        testId: Tests[newIndex],
      });

      navigate(`${Paths.Results}?${params.toString()}`);
    }
  };

  const navigateToMyTests = () => {
    navigate(Paths.MyTests);
    const params = new URLSearchParams({
      testId: testTitle,
    });

    navigate(`${Paths.MyTests}?${params.toString()}`);
  }

  return (
    <div className="leaderboard-container">
      <div className="leaderboard-header">
        <div className="results-selected-test">
          <button className={index !== 0 ? "results-selected-test-btn-scroll" : "inactive-hide-btn-scroll"} onClick={scrollLeft}>&#8249;</button>
          <h2>{testTitle}</h2>
          <button className={index !== 1 ? "results-selected-test-btn-scroll" : "inactive-hide-btn-scroll"} onClick={scrollRight}>&#8250;</button>
        </div>
      </div>
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th style={{ paddingLeft: 30 }}>Rank</th>
            <th style={{ textAlign: "center" }}>Player Name</th>
            <th style={{ textAlign: "center" }}>Country</th>
            <th style={{ textAlign: "end", paddingRight: 50 }}>Score</th>
          </tr>
        </thead>
        <tbody ref={tableBodyRef}>
          {showLeaderboard ? results.map((player, index) => (
            <tr
              key={index}
              style={{
                backgroundColor:
                  player.username === username ? "gold" : "transparent",
              }}
            >
              <td
                className="leaderboard-rank"
                style={{ paddingLeft: 30, color: "black" }}
              >
                {player.rank}
              </td>
              <td style={{ textAlign: "center", color: "black" }}>
                {player.username}
              </td>
              <td style={{ textAlign: "center", color: "black" }}>
                {player.country}
              </td>
              <td
                style={{ textAlign: "end", paddingRight: 50, color: "black" }}
              >
                {parseFloat(player.score).toFixed(2)}
              </td>
            </tr>
          )) :
            <div className="user-has-no-results">
              <p>You have not taken the <strong>{testTitle}</strong>. To see other users results, click below to take a test.</p>
              <button onClick={navigateToMyTests}>Take a test</button>
            </div>
          }
          {isFixedVisible && (
            <tr
              style={{ position: "sticky", bottom: 0, backgroundColor: "gold" }}
            >
              <td
                className="leaderboard-rank"
                style={{ paddingLeft: 30, color: "black" }}
              >
                {results[fixedIndex].rank}
              </td>
              <td style={{ textAlign: "center", color: "black" }}>
                {username}
              </td>
              <td style={{ textAlign: "center", color: "black" }}>
                {results[fixedIndex].country}
              </td>
              <td
                style={{ textAlign: "end", paddingRight: 50, color: "black" }}
              >
                {parseFloat(results[fixedIndex].score).toFixed(2)}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;
