import React, { useState } from "react";
import "./profile.css";
import EditMode from "./components/edit-mode";
import StaticMode from "./components/static-mode";
import Spinner from "../spinner/spinner";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { Paths } from "../helpers";

const Profile = ({
  idToken,
  generalTableData,
  setGeneralTableData,
  setInError,
}) => {
  const [inEditMode, setInEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const closeOnClick = () => {
    navigate(Paths.Home)
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="profile-root">
      <div className="profile-div">
        <div className="profile-heading">
          <h1>{generalTableData.username}</h1>
          <FaTimes className="profile-close-icon" onClick={closeOnClick}/>
        </div>
        <div className="profile-details">
          {inEditMode ? (
            <EditMode
              idToken={idToken}
              generalTableData={generalTableData}
              setGeneralTableData={setGeneralTableData}
              setIsLoading={setIsLoading}
              setInEditMode={setInEditMode}
              setInError={setInError}
            />
          ) : (
            <StaticMode
              generalTableData={generalTableData}
              setInEditMode={setInEditMode}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
