import React from 'react';
import "./contact.css"

const Contact = () => {
  return (
    <div className="contact">
        <h1>Contact</h1>
        <p>Admin@IBIS100.com</p>
        <p>Amsterdam, 1072VC</p>
        <p>©2024 IBIS™</p>
        <p>Designed by Investment Bankers</p>
    </div>
  );
};

export default Contact;
