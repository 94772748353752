import React, { useEffect, useRef, useState } from "react";
import "./my-tests.css";
import PurchasedTests from "../components/purchased-tests";
import CompletedTests from "../components/completed-tests";
import ErrorFlag from "../../error-flag/error-flag";
import TestInformation from "../components/test-information";
import { useLocation } from "react-router-dom";

const MyTests = ({
  generalTableData,
  inError,
  setInError,
  setSelectedTestId,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const testId = queryParams.get("testId") ?? 'IBIS100';
  const selectedTest = testId;
  const divRef = useRef(null);

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
  
    setVh();
    window.addEventListener("resize", setVh);
  
    return () => {
      window.removeEventListener("resize", setVh);
    };
  }, []);

  const testsCompleted = generalTableData.testsCompleted.filter(test => {
    if (test.id === selectedTest) return test;
  });
  if (testsCompleted.length === 0) {
    testsCompleted[0] = {};
    testsCompleted[0]["completedDate"] = "-";
    testsCompleted[0]["id"] = "-";
  }

  const testsPurchased = generalTableData.testsPurchased.filter(test => {
    if (test.id === selectedTest) return test;
  });
  if (testsPurchased.length === 0) {
    testsPurchased[0] = {};
    testsPurchased[0]["purchasedDate"] = "-";
    testsPurchased[0]["id"] = "-";
  }
  
  return (
    <div className="mytests-root">
      {inError ? <ErrorFlag setInError={setInError} /> : null}
      <div className="mytests" ref={divRef}>
        <TestInformation generalTableData={generalTableData} setSelectedTestId={setSelectedTestId} selectedTest={selectedTest} />
        <div className="mytests-second-row">
            <PurchasedTests purchasedTests={testsPurchased} setSelectedTestId={setSelectedTestId} />
            <CompletedTests completedTests={testsCompleted}  />
        </div>
      </div>
    </div>
  );
};

export default MyTests;
