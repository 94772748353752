import React, { useState } from "react";
import "./test-portal.css";
import { useNavigate } from "react-router-dom";
import { Paths } from "../helpers/constants";
import { FaTimes } from "react-icons/fa";
import Spinner from "../spinner/spinner";
import { fetchRequest } from "../helpers/fetch-function";
import { getCurrentDate } from "../helpers/get-current-date";
import PageEnteredIncorrectly from "../helpers/shared-components/page-entered-incorrectly";
import { examStartedMockResponse, prepareTestMockResponse, testMockData } from '../helpers';
import { getInProgressTestMockResponse } from "../helpers/mock-data/in-progress-test";

const TestPortal = ({
  idToken,
  setInError,
  generalTableData,
  setGeneralTableData,
  selectedTestId,
  setSelectedTestId,
  setTestData,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const testId = selectedTestId;
  const validTest = testId === "" ? false : true;

  const onClose = async () => {
    setSelectedTestId("");
    const params = new URLSearchParams({
      testId: testId,
    });

    navigate(`${Paths.MyTests}?${params.toString()}`);
  };

  const onStartTestClick = async () => {
    setIsLoading(true);
    try {
      const currentDate = getCurrentDate();

      const examStartedResponse = await fetchRequest(idToken, "examStarted", "POST", {
        testId: testId,
        completedDate: currentDate,
      });
      // const examStartedResponse = examStartedMockResponse;

      const prepareTestResponse = await fetchRequest(idToken, "prepareInProgressTest", "POST", {
        testId: testId,
        country: generalTableData.country,
        username: generalTableData.username,
      });
      // const prepareTestResponse = prepareTestMockResponse;

      const getInProgressResponse = await fetchRequest(idToken, "getInProgressTest", "GET");
      const testData = await getInProgressResponse.json();
      // const getInProgressResponse = getInProgressTestMockResponse;
      // const testData = testMockData;

      if (examStartedResponse.status === 200 && prepareTestResponse.status === 200 && getInProgressResponse.status === 200) {
        setTestData(testData);

        const index = generalTableData.testsPurchased.findIndex(test => test.id === testId);
        if (index !== -1) {
          generalTableData.testsPurchased.splice(index, 1);
        }

        if (generalTableData.testsPurchased.length === 0) {
          generalTableData.testsPurchased.push({ id: "-", purchasedDate: "-" });
        }
        setGeneralTableData(generalTableData);

        generalTableData.testsCompleted.push({ id: testId, completedDate: currentDate });
        generalTableData.testsCompleted = generalTableData.testsCompleted.filter((item) => item.id !== "-");
        setGeneralTableData(generalTableData);
        navigate(Paths.TestSession);
      } else {
        navigate(Paths.MyTests);
        setInError(true);
      }
    } catch (error) {
      console.error("Start test session", error);
    } finally {
      setIsLoading(false);
      setSelectedTestId("");
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (!validTest) {
    return (
      <PageEnteredIncorrectly message="You have not selected a valid test. Please go to 'MyTests', 'Your Upcoming Tests' and
      select a test you want to start."/>
    );
  }

  return (
    <div className="pre-test">
      <div className="pre-test-div">
        <div className="pre-test-close-div">
          <button onClick={onClose}>
            <FaTimes className="booking-portal-close-icon" />
          </button>
        </div>
        {validTest ? (
          <div className="test-portal-information">
            <h1>
              You are about to begin the {testId}. Before starting your test, please read and understand the below:
            </h1>
            <div className="rules">
              {
                testId === 'IBIS100' ?
                  <p>1. You will have 100 minutes to answer 100 questions.</p>
                  :
                  <p>1. You will have 6 minutes to answer 6 questions.</p>
              }
              <p>2. All questions are multiple choice.</p>
              <p>
                3. Please ensure you have a stable internet connection during
                the test.
              </p>
              <p>
                4. Reloading the page or exiting the test will result in your
                test session being terminated and considered complete.
              </p>
              <p>
                5. Make sure you have everything prepared, clicking "Start Test"
                will put you in your test session and your test will begin.
              </p>
            </div>
            <div className="pre-test-start">
              <button onClick={onStartTestClick}>Start test</button>
            </div>
          </div>
        ) : (
          <div className="no-tests-available">
            You have not selected a valid test. Please go to 'MyTests', 'Your Upcoming Tests' and
            select a test you want to start.
          </div>
        )}
      </div>
    </div>
  );
};

export default TestPortal;
