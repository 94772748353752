import React, { useEffect } from "react";
import "./home.css";
import Welcome from "../components/welcome";
import HowItWorks from "../components/how-it-works";
import UserStats from "../components/user-stats";

const Home = ({ generalTableData, allResults }) => {
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    const handleTouchStart = () => {
      window.scrollTo(0, 1);
    };

    setVh();
    window.addEventListener("resize", setVh);
    document.addEventListener("touchstart", handleTouchStart);

    return () => {
      window.removeEventListener("resize", setVh);
      document.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);

  return (
    <div className="home-container">
      <div className="white-background">
        <div className="home-components">
          <Welcome userName={generalTableData.username} />
          <UserStats generalTableData={generalTableData} allResults={allResults} />
          <HowItWorks />
        </div>
      </div>
    </div>
  );
};

export default Home;
