import React from "react";
import "./welcome.css";

const Welcome = ({ userName }) => {
  return (
    <div className="welcome-root">
      <div className="welcome">
        <div className="welcome-div">
          <h1>Welcome, {userName}!</h1>
          <div className="welcome-info-div">
            <p>
              The IBIS exam is designed to assess your Investment Banking
              Intelligence Score, aiding in your preparation for interviews with
              leading investment banking firms. This exam replicates the actual
              interview experience, offering a realistic practice environment.
              Developed by seasoned investment bankers, the IBIS is tailored
              specifically for professionals in the investment banking sector.
            </p>
          </div>
        </div>
        <div className="sections-background-div">
          <div className="sections-div">
            <h2>The test comprises the following evaluation sections:</h2>
            <p>&bull; Accounting</p>
            <p>&bull; Valuation Methods</p>
            <p>&bull; M&A and LBO</p>
            <p>&bull; Enterprise Value and Equity Value</p>
            <p>&bull; DCF Analysis</p>
            <p>&bull; Industry Multiples</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
