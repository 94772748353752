import React, { forwardRef }   from "react";
import "./leader-board-info.css";
import Leaderboard from './images/leader-board.png';
import Logos from './images/logos.png';

const LeaderboardInformation = forwardRef((props, ref) => {
  return (
    <section className="leader-board-info" ref={ref}>
      <div className="leader-board-info-heading">
        <h1>Access the global leaderboard to compare your score with the world's best</h1>
      </div>
      <div className="leader-board-image">
        <img src={Leaderboard}/>
      </div>
      <div className="logos">
        <img src={Logos}/>
      </div>
    </section>
  );
});

export default LeaderboardInformation;
