import React, { useState, useEffect } from "react";
import "./check-box.css";
import CheckoutForm from "../../stripe/components/checkout-form";

const Checkbox = ({
  idToken,
  sessionId,
  setSessionId,
  paymentShowingDivRef,
  setScrollToPaymentView,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [labelStyle, setLabelStyle] = useState({});
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);

  useEffect(() => {
    if (sessionId) {
      setShowCheckoutForm(true);
    }
  }, [sessionId]);

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);

    if (checked) {
      setLabelStyle({});
    }
  };

  const handleButtonClick = () => {
    if (!isChecked) {
      setLabelStyle({ color: 'red' });
    } else {
      setShowCheckoutForm(true);
      setIsChecked(false);
    }
  };

  return (
    showCheckoutForm ? (
      <div className="payment-div" >
        <div className="checkoutform" >
          <CheckoutForm
            idToken={idToken}
            setShowCheckoutForm={setShowCheckoutForm}
            sessionId={sessionId}
            setSessionId={setSessionId}
            paymentShowingDivRef={paymentShowingDivRef}
            setScrollToPaymentView={setScrollToPaymentView}
          />
        </div>
      </div>
    ) : (
      <div className="checkbox">
        <div className="checkbox-div">
          <input
            type="checkbox"
            id="uniqueCheckboxId"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="uniqueCheckboxId" style={labelStyle}>
            I agree to the terms and conditions of this exam.
          </label>
        </div>
        <div className="purchase-test-button-div" id="purchase-test-button-div">
          <button className="purchase-test-button" id="purchase-test-button" onClick={handleButtonClick}>
            Purchase Test
          </button>
        </div>
      </div>
    )
  );
};

export default Checkbox;
