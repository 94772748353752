import React from "react";
import "./error-flag.css";
import { FaTimes } from "react-icons/fa";

const ErrorFlag = () => {

  const onClose = async () => {
    window.location.reload();
  };

  return (
    <div className="error-flag">
      <div className="error-flag-information">
        <p>
          We have encountered an error, please wait a few minutes and try again, or
          reload the page. If this issue persists, please contact admin@ibis100.com.
        </p>
      </div>
      <div className="error-flag-close">
          <FaTimes className="close-icon" onClick={onClose}/>
      </div>
    </div>
  );
};

export default ErrorFlag;
