import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./folder.css";
import { Paths, TestsInformation } from "../../helpers/constants";
import { FaFolderClosed } from "react-icons/fa6";
import { FaFolderOpen } from "react-icons/fa";

const CompletedTests = ({ completedTests }) => {
  const navigate = useNavigate();
  const [folderOpened, setFolderOpened] = useState(true);
  const completedTestsExit = completedTests[0].completedDate !== "-";

  const handleOnClick = () => {
    navigate(Paths.Results);
  };

  const handleOpenFolderClick = () => {
    setFolderOpened(!folderOpened);
  }

  completedTests.sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });
  return (
    <div className="folder-tests">
      <div className="folder-tests-div">
        <div className="tests-heading" onClick={handleOpenFolderClick}>
          <h1>Completed Tests</h1>
          {folderOpened ? <FaFolderOpen /> : <FaFolderClosed />}
        </div>
        {folderOpened ?
          <div className="tests-folder">
            <div className="open-folder-initial-row">
              <div>
                <label>Test ID</label>
              </div>
              <div>
                <label>Completed on</label>
              </div>
              <div>
                <label>Results</label>
              </div>
            </div>
            <div className="open-folder-tests-collection">
              {completedTestsExit ? completedTests.map((result, index) => {
                return (
                  <div className="open-folder-test-info">
                    <div>
                      <label>{result.id}</label>
                    </div>
                    <div>
                      <label>{result.completedDate}</label>
                    </div>
                    <div>
                      <p onClick={handleOnClick}>See results</p>
                    </div>
                  </div>
                )
              }) :
                <div className="open-folder-test-info">
                  <div>
                    <label>-</label>
                  </div>
                  <div>
                    <label>-</label>
                  </div>
                  <div>
                    <label>-</label>
                  </div>
                </div>
              }
            </div>
          </div>
          : null}
      </div>
    </div>
  );
};

export default CompletedTests;
