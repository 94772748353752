export const fetchRequest = async (idToken, path, method, body, keepalive) => {
  return await fetch(
    `https://yq2cg9dnz6.execute-api.eu-central-1.amazonaws.com/prod/${path}`,
    {
      method: method,
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: method === "POST" ? JSON.stringify(body) : undefined,
      keepalive: keepalive ?? undefined,
    }
  );
};
