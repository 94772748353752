export const Paths = {
  Login: "/login",
  SignUp: "/signUp",
  BookATest: "/booktest",
  Home: "/home",
  MyTests: "/mytests",
  Results: "/results",
  TestPortal: "/testportal",
  TestSession: "/testportal/testsession",
  Profile: "/profile",
  Payment: "/payment",
  PaymentReturn: "/payment/return",
  MobileSideBarNavigation: "/mobile/navigation",
};

export const Tests = ["Practice test", "IBIS100"];
export const TestsInformation = {
  "Practice test": { price: "Free", numberOfQuestions: 6, duration: '6 mins.' },
  "IBIS100": { price: "$10", numberOfQuestions: 100, duration: '100 mins.' },
};
