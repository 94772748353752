import React, { useState, useEffect } from "react";
import "./test-information.css";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../helpers/constants";

const TestInformation = ({ generalTableData, setSelectedTestId, selectedTest }) => {
    const navigate = useNavigate();

    const [practiceTest, setPracticeTest] = useState(selectedTest !== 'IBIS100');
    const [attempts, setAttempts] = useState(0);
    const [attemptsLeft, setAttemptsLeft] = useState(3);

    useEffect(() => {
        setAttempts(generalTableData.testsCompleted.filter(test => test.id === selectedTest).length);
        setAttemptsLeft(generalTableData.testsPurchased.filter(test => test.id === selectedTest).length);
    }, [selectedTest]);

    const testIdOnClick = (pracTest) => {
        setPracticeTest(pracTest);
        const params = new URLSearchParams({
          testId: practiceTest ?  'IBIS100' : 'Practice test',
        });
    
        navigate(`${Paths.MyTests}?${params.toString()}`);
    }

    const stringOnClick = () => {
        navigate(Paths.BookATest);
    }


    const navigateToResults = () => {
        const params = new URLSearchParams({
            testId: selectedTest,
        });

        navigate(`${Paths.Results}?${params.toString()}`);
    }

    const navigateToBookATest = () => {
        navigate(Paths.BookATest);
    }

    const navigateToTestPortal = () => {
        setSelectedTestId(selectedTest);
        navigate(Paths.TestPortal);
    }

    return (
        <div className="test-information-root">
            <div className="test-ids">
                <div className={practiceTest ? "test-ids-div" : "test-ids-div-active"} onClick={() => testIdOnClick(false)}>
                    <h2 style={{ textDecoration: practiceTest ? "none" : "underline" }}>IBIS100 test</h2>
                </div>
                <div className={practiceTest ? "test-ids-div-active" : "test-ids-div"} onClick={() => testIdOnClick(true)}>
                    <h2 style={{ textDecoration: practiceTest ? "underline" : "none" }}>Practice test</h2>
                </div>
            </div>
            <div className="test-information-second-row">
                <div className="test-information-second-row-left-hand-side">
                    <div className="tisrlhs-second-row">
                        <div className="test-information-description">
                            {practiceTest ? <p>The <strong>practice test</strong> allows you to familiarize yourself with the test format and content. It’s a great way to gauge your knowledge and identify areas for improvement before taking the main IBIS test.</p> : <p>The <strong>IBIS100 test</strong> is a comprehensive assessment designed to simulate real interview conditions for investment banking roles. It serves as your final test, assessing your readiness and helping you pinpoint key strengths and development areas.</p>}
                            {practiceTest ? <p>This free test is available anytime to support your preparation. You can attempt it up to 3 times, with 6 multiple-choice questions and a 6-minute time limit for each attempt.</p> : <p>The IBIS100 test can be attempted as often as needed. To begin, purchase the test <strong className="book-test-via-text" onClick={stringOnClick}>here</strong> for $20, then return to this page to start. The test includes 100 multiple-choice questions, with a time limit of 100 minutes to complete them.</p>}
                        </div>
                    </div>
                    <div className="tisrlhs-top-row">
                        <div className="tisrlhs-top-row-left">
                            <div className="tisrlhs-top-row-stat">
                                <h1 className="attempt-heading" style={{ textDecoration: 'underline' }}>Test attempts available:</h1>
                                <h1 className="attempt-value" style={{ color: attemptsLeft > 0 ? 'green' : 'red' }}>{attemptsLeft}</h1>
                            </div>
                            <div className="tisrlhs-top-row-stat">
                                <h1 className="attempt-heading" style={{ textDecoration: 'underline' }}>Test attempts:</h1>
                                <h1 className="attempt-value" style={{ color: attempts > 0 ? 'green' : 'red' }}>{attempts}</h1>
                            </div>
                        </div>
                        <div className="tisrlhs-top-row-right">
                            <div className="tisrlhs-top-row-stat">
                                <h1 className="attempt-heading" style={{ textDecoration: 'underline' }}>Take test:</h1>
                                {attemptsLeft === 0 && selectedTest === 'IBIS100' ?
                                    <div className="status-of-test-available">
                                        <p style={{ color: 'red', fontWeight: 'bold' }}>Unavailable:</p>
                                        <button className="tisrlhs-top-row-stat-button" onClick={navigateToBookATest}>Book a test</button>
                                    </div> : attemptsLeft > 0 ?
                                        <div className="status-of-test-available">
                                            <p style={{ color: 'green', fontWeight: 'bold' }}>Available:</p>
                                            <button className="tisrlhs-top-row-stat-button" onClick={navigateToTestPortal}>Begin test</button>
                                        </div> :
                                        <div className="status-of-test-available">
                                            <p style={{ color: 'red', fontWeight: 'bold' }}>Unavailable</p>
                                        </div>
                                }
                            </div>
                            <div className="tisrlhs-top-row-stat">
                                <h1 className="attempt-heading" style={{ textDecoration: 'underline' }}>Compelted test results:</h1>
                                {attempts === 0 ?
                                    <div className="status-of-test-available">
                                        <p style={{ color: 'red', fontWeight: 'bold' }}>Unavailable</p>
                                    </div> :
                                    <div className="status-of-test-available">
                                        <p style={{ color: 'green', fontWeight: 'bold' }}>Available:</p>
                                        <button className="tisrlhs-top-row-stat-button" onClick={navigateToResults}>Results</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="test-information-second-row-right-hand-side">
                    pp
                </div>
            </div>
        </div>
    );
};

export default TestInformation;
