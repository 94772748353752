import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./section-bar-chart.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: true,
    },
  },
  scales: {
    y: {
      min: 0,
      max: 100,
      ticks: {
        callback: function (value) {
          return value + "%";
        },
        font: {
          weight: "bold",
        },
        color: "rgb(91, 88, 88)",
      },
    },
    x: {
      ticks: {
        font: {
          weight: "bold",
          size: "8%",
        },
        color: "rgb(91, 88, 88)",
      },
    },
  },
};

const SectionBarChart = ({
  userSections,
  globalSections,
  username,
  sections,
  countrySections,
  country,
}) => {
  const labels = sections;

  const gs = globalSections.map(value => parseFloat(value).toFixed(2));
  const us = userSections.map(value => parseFloat(value).toFixed(2));
  const cs = countrySections.map(value => parseFloat(value).toFixed(2));

  const data = {
    labels,
    datasets: [
      {
        label: username,
        data: us,
        backgroundColor: "#7d81e2",
        borderRadius: 5,
        width: 10,
        fontSize: '70%',
      },
      {
        label: country,
        data: cs,
        backgroundColor: "green",
        borderRadius: 5,
        fontSize: '70%',
      },
      {
        label: "Global",
        data: gs,
        backgroundColor: "red",
        borderRadius: 5,
        fontSize: '70%',
      },
    ],
  };

  return (
    <div className="section-barchart">
      <Bar className="barchart" options={options} data={data} />
    </div>
  );
};

export default SectionBarChart;
