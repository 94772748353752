import React, { useRef, useState, useEffect } from "react";
import "./book-a-test.css";
import Title from "../components/title";
import Guidelines from "../components/guidelines";
import Checkbox from "../components/check-box";

const BookATest = ({ idToken }) => {
  const divRef = useRef(null);
  const [sessionId, setSessionId] = useState(null);
  const paymentShowingDivRef = useRef(null);
  const [scrollToPaymentView, setScrollToPaymentView] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("sessionId")) {
      setSessionId(localStorage.getItem("sessionId"));
      localStorage.removeItem("sessionId");
    }
  }, [idToken]);

  if (scrollToPaymentView) {
    setTimeout(() => {
      if (paymentShowingDivRef.current) {
        paymentShowingDivRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
  }

  return (
    <div className="bookatest-root" id="bookatest-root">
      <div className="bookatest" ref={divRef}>
        <div className="bookatest-components">
          <Title />
          <Guidelines />
          <Checkbox 
            idToken={idToken} 
            sessionId={sessionId} 
            setSessionId={setSessionId} 
            paymentShowingDivRef={paymentShowingDivRef}
            setScrollToPaymentView={setScrollToPaymentView}
          />
        </div>
      </div>
    </div>
  );
};

export default BookATest;
