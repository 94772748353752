export const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number);

    return new Date(year, month - 1, day);
}

export const sortDates = (dateArray) => {
    return dateArray.sort((a, b) => {
        const dateA = parseDate(a);
        const dateB = parseDate(b);

        return dateA - dateB;
    });
}

