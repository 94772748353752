import React, { useState, useEffect } from "react";
import "./edit-mode.css";
import { FaCheck, FaTimes } from "react-icons/fa";
import {
  parsePhoneNumber,
  getCountries,
  getCountryCallingCode,
} from "libphonenumber-js";
import { fetchRequest } from "../../helpers";
import { FaFolderClosed } from "react-icons/fa6";
import { FaFolderOpen } from "react-icons/fa";

const EditMode = ({
  idToken,
  generalTableData,
  setGeneralTableData,
  setInEditMode,
  setIsLoading,
  setInError,
}) => {
  const [infoSharingStatus, setInfoSharingStatus] = useState(
    generalTableData.allowSharingOfInformation ?? false
  );
  const [emailStatus, setEmailStatus] = useState(
    generalTableData.allowSendingOfEmails ?? false
  );
  const [phoneError, setPhoneError] = useState("");
  const [inputFullName, setInputFullName] = useState(
    generalTableData.fullName ?? ""
  );
  const [inputPhoneNumber, setInputPhoneNumber] = useState(
    generalTableData.phoneNumber === "-" ? "" : generalTableData.phoneNumber ?? ""
  );
  const [personalInformationOpen, setPersonalInformationOpen] = useState(true);

  const [countryCodes, setCountryCodes] = useState([]);

  const [selectedCountryCode, setSelectedCountryCode] = useState("+1");
  const phoneNumber = `${selectedCountryCode}${inputPhoneNumber}`;

  const originalData = {
    fullName: generalTableData.fullName,
    phoneNumber: generalTableData.phoneNumber,
    allowSharingOfInformation: generalTableData.allowSharingOfInformation,
    allowSendingOfEmails: generalTableData.allowSendingOfEmails,
  };

  useEffect(() => {
    const countryList = getCountries();
    setCountryCodes(countryList);

    if (generalTableData.phoneNumber && generalTableData.phoneNumber !== "-") {
      const parsedNumber = parsePhoneNumber(generalTableData.phoneNumber);
      const countryCode = parsedNumber.countryCallingCode;
      const nationalNumber = parsedNumber.nationalNumber;

      setSelectedCountryCode(`+${countryCode}`);
      setInputPhoneNumber(nationalNumber);
    }
  }, []);

  const onPersonalInformationClick = () => {
    setPersonalInformationOpen(!personalInformationOpen);
  }

  const handleCountryCodeChange = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const onClickEmailStatus = () => {
    setEmailStatus(!emailStatus);
  };

  const onClickInfoStatus = () => {
    setInfoSharingStatus(!infoSharingStatus);
  };

  const onSaveClick = async () => {
    let hasError = false;

    if (inputPhoneNumber !== "" && inputPhoneNumber) {
      try {
        const parsedNumber = parsePhoneNumber(phoneNumber);

        if (!parsedNumber.isValid()) {
          setPhoneError("Invalid phone number entered.");
          hasError = true;
        } else {
          setPhoneError("");
        }
      } catch (error) {
        setPhoneError("Invalid phone number entered.");
        hasError = true;
      }
    } else {
      setPhoneError("");
    }

    if (!hasError) {
      setIsLoading(true);

      const newPhoneNumber = inputPhoneNumber !== "" && inputPhoneNumber !== "-" && inputPhoneNumber !== undefined;

      try {
        const body = {
          fullName: inputFullName,
          phoneNumber: newPhoneNumber ? phoneNumber : "-",
          allowSharingOfInformation: infoSharingStatus,
          allowSendingOfEmails: emailStatus,
        };

        if (JSON.stringify(originalData) !== JSON.stringify(body)) {
          const response = await fetchRequest(idToken, "updateProfile", "POST", body);
          // const response = { status: 300 };

          if (response.status === 200) {
            const newGeneralTableData = generalTableData;
            newGeneralTableData.fullName = inputFullName;
            newGeneralTableData.phoneNumber = newPhoneNumber ? phoneNumber : "-";
            newGeneralTableData.allowSendingOfEmails = emailStatus;
            newGeneralTableData.allowSharingOfInformation = infoSharingStatus;
            setGeneralTableData(newGeneralTableData);
          } else {
            setInError(true);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }

      setInEditMode(false);
    }
  };

  return (
    <div className="edit-mode-root">
      <div className="personal-information-folder">
        <div className="personal-information-header" onClick={onPersonalInformationClick}>
          <label>Personal Information</label>
          <label>{personalInformationOpen ? <FaFolderOpen /> : <FaFolderClosed />}</label>
        </div>
        {personalInformationOpen ?
          <div className="personal-information">
            <div className="edit-mode-div">
              <label htmlFor="fullname">Full name:</label>
              <input
                className="fullname-input"
                type="text"
                id="fullname"
                name="fullname"
                placeholder="John Doe"
                value={inputFullName}
                onChange={(e) => setInputFullName(e.target.value)}
                required
              />
            </div>
            <div className="edit-mode-div">
              <label htmlFor="dob">Date of Birth:</label>
              <label>{generalTableData.dob}</label>
            </div>
            <div className="edit-mode-div">
              <label htmlFor="dob">Country of origin:</label>
              <label>{generalTableData.country ?? "-"}</label>
            </div>
            <div className="edit-mode-div">
              <label htmlFor="phonenumber">Phone number:</label>
              <div className="edit-mode-phone-number">
                <select
                  id="countrycode"
                  className="edit-mode-country-code-input"
                  value={selectedCountryCode}
                  onChange={handleCountryCodeChange}
                  required
                >
                  {countryCodes.map((country) => (
                    <option
                      key={country}
                      value={`+${getCountryCallingCode(country)}`}
                    >
                      {`${country} (+${getCountryCallingCode(country)})`}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  id="phonenumber"
                  className="edit-mode-phone-number-input"
                  placeholder="123456789"
                  value={inputPhoneNumber}
                  onChange={(e) => setInputPhoneNumber(e.target.value)}
                  required
                />
              </div>
              {phoneError && <span className="edit-mode-error">{phoneError}</span>}
            </div>
          </div>
          : null}
      </div>
      <div className="edit-mode-consents-div">
        {infoSharingStatus ? (
          <FaCheck
            size={24}
            className="edit-mode-consents-icon"
            onClick={onClickInfoStatus}
            style={{ color: "blue" }}
          />
        ) : (
          <FaTimes
            size={24}
            className="edit-mode-consents-icon"
            onClick={onClickInfoStatus}
            style={{ color: "red" }}
          />
        )}
        I allow IBIS to share my results and contact information with IBIS
        recruiters.
      </div>
      <div className="edit-mode-consents-div">
        {emailStatus ? (
          <FaCheck
            size={24}
            className="edit-mode-consents-icon"
            onClick={onClickEmailStatus}
            style={{ color: "blue" }}
          />
        ) : (
          <FaTimes
            size={24}
            className="edit-mode-consents-icon"
            onClick={onClickEmailStatus}
            style={{ color: "red" }}
          />
        )}
        I allow IBIS to send me emails about updates for new tests and other
        things.
      </div>
      <div className="edit-mode-bottom">
        <button onClick={onSaveClick}>Save</button>
      </div>
    </div>
  );
};

export default EditMode;
