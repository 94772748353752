import React from 'react';
import "./signal-competence.css"
import { useNavigate } from "react-router-dom";
import { Paths } from '../../helpers';

const SignalCompetence = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(Paths.Home);
  }

  return (
    <div className="signal-competence">
      <div className="heading">
        <h1>Signal your competence to get invited to interviews...</h1>
      </div>
      <div className="info">
        <p>Most student and young professionals who want to break into investment banking never make it through the CV rounds. Take the IBIS and signal your competence to the world's leading financial advisors</p>
      </div>
      <div className="get-started">
        <button className="get-started-button" onClick={onClick}>Get Started</button>
      </div>
    </div>
  );
};

export default SignalCompetence;
