import React, { useState, useEffect, useRef } from "react";
import "./test-session.css";
import PostTest from "../components/post-test";
import { fetchRequest } from "../../helpers";
import Spinner from "../../spinner/spinner";
import { CgWebsite } from "react-icons/cg";
import PageEnteredIncorrectly from "../../helpers/shared-components/page-entered-incorrectly";

const TestSession = ({ idToken, testData }) => {
  const itemsPerPage = 25;

  const [userAnswers, setUserAnswers] = useState([]);
  const [endDate, setEndDate] = useState("No Value Set")
  const [questionSet, setQuestionSet] = useState([]);
  const [noInProgressTest, setNoInProgressTest] = useState(false);
  const [testLength, setTestLength] = useState(0);
  const [index, setIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(100);
  const [selectedOption, setSelectedOption] = useState("");
  const [questionText, setQuestionText] = useState("");
  const [answers, setAnswers] = useState([]);
  const [attemptEndSession, setAttemptEndSession] = useState(false);
  const [endSession, setEndSession] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTestsStatus, setShowTestsStatus] = useState(false);
  const testsStatusRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);

  const page = index;

  useEffect(() => {
    if (testData !== "No Value Set") {
      const tempEndDate = new Date();
      tempEndDate.setSeconds(tempEndDate.getSeconds() + testData.examTimeLength);

      const currentTime = new Date().getTime();
      const remainingTimeMs = tempEndDate.getTime() - currentTime;
      const remainingTime = Math.floor(remainingTimeMs / 1000);
      setTimeLeft(remainingTime > 0 ? remainingTime : 0);
      setQuestionSet(testData.questions);
      setEndDate(tempEndDate.getTime());
      setTestLength(testData.examTimeLength);
      setQuestionText(testData.questions[0].questionText);
      setAnswers(testData.questions[0].answers);

      const tempUserAnswers = []
      for (let i = 0; i < testData.numberOfQuestions; i++) {
        tempUserAnswers[i] = "";
      }
      setUserAnswers(tempUserAnswers);

    } else {
      setNoInProgressTest(true);
    }
  }, [testData])

  useEffect(() => {
    if (!noInProgressTest) {
      if (timeLeft === 0 && endDate !== "No Value Set") {
        yesOnClick();
        return;
      }

      const timerId = setInterval(() => {
        const remainingTimeMs = endDate - new Date().getTime();
        const remainingTime = Math.floor(remainingTimeMs / 1000);

        setTimeLeft(remainingTime > 0 ? remainingTime : 0);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [timeLeft, testLength]);

  const handleClickOutside = (event) => {
    if (
      testsStatusRef.current &&
      !testsStatusRef.current.contains(event.target)
    ) {
      setShowTestsStatus(false);
    }
  };

  useEffect(() => {
    if (showTestsStatus) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showTestsStatus]);

  if (noInProgressTest) {
    return (
      <PageEnteredIncorrectly message="There is no active test session currently. Please go to 'MyTests', 'Your Upcoming Tests' and
      select a test you want to start."/>
    );
  }

  const maxPage = Math.ceil(questionSet.length / itemsPerPage) - 1;

  const handleNextPage = () => {
    if (currentPage < maxPage) {
      setCurrentPage(currentPage+1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage-1);
    }
  };

  const getPaginatedItems = () => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return questionSet.slice(start, end);
  };

  function fireAndForget(updatedAnswers) {
    setTimeout(() => {
      fetchRequest(idToken, 'updateInProgressTest', 'POST', {
        results: updatedAnswers,
        endDate: new Date().getTime() + endDate,
      });
    }, 0);
  }

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = selectedValue;
    setUserAnswers(updatedAnswers);
    fireAndForget(updatedAnswers);
  };

  const handleSubmitTest = () => {
    setAttemptEndSession(true);
  };

  const handleNextQuestionClick = () => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = selectedOption;
    setUserAnswers(updatedAnswers);

    const newIndex = index + 1;
    setIndex(newIndex);
    setQuestionText(questionSet[newIndex].questionText);
    setAnswers(questionSet[newIndex].answers);
    setSelectedOption(updatedAnswers[newIndex] || "");
  };

  const handlePreviousQuestionClick = () => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = selectedOption;
    setUserAnswers(updatedAnswers);

    const newIndex = index - 1;
    setIndex(newIndex);
    setQuestionText(questionSet[newIndex].questionText);
    setAnswers(questionSet[newIndex].answers);
    setSelectedOption(updatedAnswers[newIndex] || "");
  };

  const postExamResults = async () => {
    setIsLoading(true);
    try {
      const timeTaken = Math.floor((testLength - (endDate - new Date().getTime()) / 1000))
      await fetchRequest(idToken, "deleteInProgressTest", "POST", {
        results: userAnswers,
        timeTaken: timeTaken,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const showTests = () => {
    setShowTestsStatus((prevState) => !prevState);
  };

  const noOnClick = () => {
    setAttemptEndSession(false);
  };

  const handleTestsStatusOnClick = (index) => {
    setIndex(index);
    setQuestionText(questionSet[index].questionText);
    setAnswers(questionSet[index].answers);
    setSelectedOption(userAnswers[index] || "");
  };

  const yesOnClick = () => {
    setEndSession(true);
    postExamResults();
  };

  const resetQuestion = () => {
    let tempUserAnswers = userAnswers;
    tempUserAnswers[index] = "";
    setUserAnswers(tempUserAnswers);
    setSelectedOption("");
    fireAndForget(tempUserAnswers);
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (endSession) {
    return <PostTest />;
  }

  if (attemptEndSession) {
    return (
      <div className="test-session">
        <div className="end-session">
          <h2>Are you sure you want to submit and end your test?</h2>
          <div className="end-session-buttons">
            <button onClick={noOnClick}>No</button>
            <button onClick={yesOnClick}>Yes</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="test-session">
      <div className="test-session-div">
        <div className="test-session-timer">
          <p>
            {Math.floor(timeLeft / 60) < 10 ? `0${Math.floor(timeLeft / 60)}` : Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
          </p>
          <div className="test-session-tests-status">
            <CgWebsite className="test-session-icon" onClick={showTests} />
            {showTestsStatus && (
              <div className="tests-status" ref={testsStatusRef}>
                <div className="pagination-buttons">
                  <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 0}
                  >
                    Previous
                  </button>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === maxPage}
                  >
                    Next
                  </button>
                </div>
                <div className="tests-status-grid">
                  {getPaginatedItems().map((_, index) => (
                    <div
                      key={index + (currentPage*25)}
                      className="test-square"
                      onClick={() => handleTestsStatusOnClick(index + (currentPage*25))}
                      style={{
                        backgroundColor: userAnswers[index + (currentPage*25)] !== ""
                          ? "lightblue"
                          : "transparent",
                        borderColor: page === index ? 'red' : 'black',
                      }}
                    >
                      {index + 1 + (currentPage*25)}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="test-session-question">
          <p>{questionText}</p>
        </div>
        <div className="test-session-answers">
          {answers.map((option, index) => (
            <label key={index}>
              <input
                type="radio"
                value={option}
                checked={selectedOption === option}
                onChange={handleOptionChange}
              />
              {option}
            </label>
          ))}
        </div>
        <div className="reset-test-question-div">
          <label onClick={resetQuestion}>Reset question</label>
        </div>
        <div className="test-session-bottom-navigation">
          {index !== 0 ? (
            <button onClick={handlePreviousQuestionClick}>
              Previous Question
            </button>
          ) : null}
          {index !== questionSet.length - 1 ? (
            <button onClick={handleNextQuestionClick}>Next Question</button>
          ) : null}
          <button onClick={handleSubmitTest}>Submit exam</button>
        </div>
      </div>
    </div>
  );
};

export default TestSession;
