import React from "react";
import "./guidelines.css";

const Guidelines = () => {
  return (
    <div className="guidelines">
      <div className="guidelines-div">
        <div className="guideline">
          <h2>1. Test details</h2>
          <p>
            &bull; <strong>Duration:</strong> The test will take 100 minutes to
            complete.
          </p>
          <p>
            &bull; <strong>Number of Questions:</strong> The test consists of 100
            questions covering various topics.
          </p>
        </div>
        <div className="guideline">
          <h2>2. Preparation</h2>
          <p>
            &bull; <strong>Calculator:</strong> It is recommended that you have
            a calculator available during the test for calculations. This will
            help you manage time and accuracy.
          </p>
        </div>
        <div className="guideline">
          <h2>3. During the test</h2>
          <p>
            &bull; <strong>No Rewrites:</strong> If you close / refresh the test tab
            during the exam, the test will automatically end, and you will not
            be able to rewrite or redo the test. You will need to purchase a new
            test if you wish to try again.
          </p>
          <p>
            &bull; <strong>Review Questions:</strong> In the top right hand corner, you have the ability to
            review previously answered questions before submitting your test.
            This ensures that you are confident with your answers before
            finalizing.
          </p>
        </div>
        <div className="guideline">
          <h2>4. After the test</h2>
          <p>
            &bull; <strong>Results & Feedback:</strong> After completing the
            test, you will receive an email containing your score, a review of
            sections where you can improve. You will also be able to see your progress against other candidates who that have taken the same test.
          </p>
          <p>
            &bull; <strong>Certificate:</strong> A personalized certificate will
            be included in the email, visualizing your score and achievement in
            the exam.
          </p>
        </div>
        <div className="guideline">
          <h2>Additional Tips:</h2>
          <p>&bull; Make sure you have a stable internet connection.</p>
          <p>
            &bull; Find a quiet place to take the test to avoid distractions.
          </p>
          <p>
            &bull; Ensure that your device is fully charged or plugged in to
            avoid any interruptions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Guidelines;
