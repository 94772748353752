import React from 'react';
import './page-entered-incorrectly.css';
import { useNavigate } from "react-router-dom";
import { Paths } from "../../helpers";

const PageEnteredIncorrectly = ({ message }) => {
    const navigate = useNavigate();
    const handleExitOnClick = () => {
        navigate(Paths.Home);
    }

    return (
        <div className="unavailable-page">
            <h1>
                {message}
            </h1>
            <button onClick={handleExitOnClick}>
                Exit
            </button>
        </div>
    );
};

export default PageEnteredIncorrectly;
