import React from "react";
import "./unauthorised-header.css";
import { useNavigate } from "react-router-dom";
import IBIS from './images/ibis-logo.png';

const UnauthorisedHeader = ({ hidden, scrollToWhyTakeTheIBIS, scrollToLeaderboards }) => {
  const navigate = useNavigate();

  const handleLoginButtonClick = () => {
    navigate("/login");
  };

  return (
    <header className={`header ${hidden ? 'header-hidden' : ''}`}>
      <div className="header-content">
          <h1 className="ibis-logo">IBIS&trade;</h1>
        <div className="nav">
          <nav>
            <ul>
              <li>
                <a href="#leaderboards" onClick={(e) => {
                  e.preventDefault();
                  scrollToLeaderboards();
                }}>Leaderboards</a>
              </li>
              <li>
                <a href="#why-take-the-ibis" onClick={(e) => {
                  e.preventDefault(); 
                  scrollToWhyTakeTheIBIS();
                }}>Why Take IBIS</a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="login">
          <button
            id="login-button"
            className="login-button"
            onClick={handleLoginButtonClick}
          >
            Log In
          </button>
        </div>
      </div>
    </header>
  );
};

export default UnauthorisedHeader;
