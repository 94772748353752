import React from "react";
import "./user-stats.css";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Tests } from '../../helpers';

const UserStats = ({ generalTableData, allResults }) => {
    const isCertified = generalTableData.hasOwnProperty('certified') && generalTableData['certified'] === true;
    const testsCompleted = generalTableData["testsCompleted"][0].id === '-' ? 0 : generalTableData["testsCompleted"].length;
    const testsAvailable = generalTableData["testsPurchased"][0].id === '-' ? 0 : generalTableData["testsPurchased"].length;

    const ibisRanking = allResults[`${Tests[1]}-Leaderboard`] ? allResults[`${Tests[1]}-Leaderboard`].
        find(result => result.username === generalTableData.username)?.rank ?? 'N/A' :  'N/A';
    const ibisRankingExists = ibisRanking !== 'N/A';

    const practiceTestRanking = allResults[`${Tests[0]}-Leaderboard`] ? allResults[`${Tests[0]}-Leaderboard`].
        find(result => result.username === generalTableData.username)?.rank ?? 'N/A' : 'N/A';
    const practiceTestRankingExists = practiceTestRanking !== 'N/A';

    return (
        <div className="user-stats-root">
            <h1>Your performance:</h1>
            <div className="user-stats">
                <div className="user-stat">
                    <label className="user-stat-header">IBIS Certified</label>
                    {isCertified ? <FaCheck className="user-stat-mark" style={{ color: 'green' }} /> : <FaTimes className="user-stat-mark" style={{ color: 'red' }} />}
                    <div className="user-stat-info">
                        {isCertified ?
                            <label className="user-stat-info-label">Go to badge</label>
                            :
                            <label className="user-stat-info-label">How to get certified?</label>
                        }
                    </div>
                </div>
                <div className="user-stat">
                    <label className="user-stat-header">IBIS100 Ranking</label>
                    <label className="user-stat-value" style={{ color: ibisRankingExists ? 'green' : 'red' }}>{ibisRanking}</label>
                    <div className="user-stat-info">
                        {ibisRankingExists ?
                            <label className="user-stat-info-label">Go to leaderboard</label>
                            :
                            <label className="user-stat-info-label">How do to get ranked?</label>
                        }
                    </div>
                </div>
                <div className="user-stat">
                    <label className="user-stat-header">Practice test Ranking</label>
                    <label className="user-stat-value" style={{ color: practiceTestRankingExists ? 'green' : 'red' }}>{practiceTestRanking}</label>
                    <div className="user-stat-info">
                        {practiceTestRankingExists ?
                            <label className="user-stat-info-label">Go to leaderboard</label>
                            :
                            <label className="user-stat-info-label">How do to get ranked?</label>
                        }
                    </div>
                </div>
                <div className="user-stat">
                    <label className="user-stat-header">Tests Completed</label>
                    <label className="user-stat-value" style={{ color: testsCompleted > 0 ? 'green' : 'red' }}>{testsCompleted}</label>
                    <div className="user-stat-info">
                        {testsCompleted > 0 ?
                            <label className="user-stat-info-label">Go to leaderboard</label>
                            :
                            <label className="user-stat-info-label">How to complete a test?</label>
                        }
                    </div>
                </div>
                <div className="user-stat">
                    <label className="user-stat-header">Tests available</label>
                    <label className="user-stat-value" style={{ color: testsAvailable > 0 ? 'green' : 'red' }}>{testsAvailable}</label>
                    <div className="user-stat-info">
                        {testsAvailable > 0 ?
                            <label className="user-stat-info-label">Take a test</label>
                            :
                            <label className="user-stat-info-label">What are available tests?</label>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserStats;
