import React from "react";
import "./on-boarding.css";
import { useNavigate } from "react-router-dom";
import Companies from "./images/companies.png";
import Certificate from "./images/certificate.png";
import { Paths } from '../../helpers';

const Onboarding = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(Paths.BookATest);
  }

  return (
    <section className="onboarding">
      <div className="onboarding-info">
        <div className="onboarding-info-text">
          <h1>Discover Your Investment Banking Intelligence Score</h1>
          <p>
            The #1 technicals test for investment banking applicants. Top
            performers have placed at firms like...
          </p>
          <img src={Companies} alt="Companies Logos"/>
        </div>
        <div className="onboard-info-button">
          <button className="take-test-button" onClick={onClick}>Take a test</button>
        </div>
      </div>
      <div className="certificate">
        <h1>How do you score against others?</h1>
        <img src={Certificate} alt="Companies Logos"/>
      </div>
    </section>
  );
};

export default Onboarding;
