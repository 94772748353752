import React from "react";
import "./stats.css";

const Stats = () => {
  return (
    <section className="stats">
      <div className="stats-div">
        <div className="stat">
          <h2>{">"}1,000</h2>
          <p>IB candidates have taken the IBIS</p>
        </div>
        <div className="stat">
          <h2>100</h2>
          <p>Multiple choice questions</p>
        </div>
        <div className="stat">
          <h2>45</h2>
          <p>Minutes to complete the test</p>
        </div>
      </div>
    </section>
  );
};

export default Stats;
