import React, { useEffect, useState } from "react";
import "./folder.css";
import { Paths } from "../../helpers/constants";
import { useNavigate } from "react-router-dom";
import { TestsInformation } from "../../helpers/constants";
import { FaFolderClosed } from "react-icons/fa6";
import { FaFolderOpen } from "react-icons/fa";

const PurchasedTests = ({ purchasedTests, setSelectedTestId }) => {
  const navigate = useNavigate();
  const [uniqueTests, setUniqueTests] = useState([]);
  const [folderOpened, setFolderOpened] = useState(true);
  const purchasedTestsExit = purchasedTests[0].purchasedDate !== "-";

  useEffect(() => {
    const unique = [];
    purchasedTests.forEach(test => {
      if (!unique.some(uniqueTest => uniqueTest.id === test.id)) {
        unique.push(test);
      }
    });
    setUniqueTests(unique);
  }, [purchasedTests]);


  uniqueTests.sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });

  const handleOnClick = (testId) => {
    setSelectedTestId(testId);
    navigate(Paths.TestPortal);
  };

  const handleOpenFolderClick = () => {
    setFolderOpened(!folderOpened);
  }
  return (
    <div className="folder-tests">
      <div className="folder-tests-div">
        <div className="tests-heading" onClick={handleOpenFolderClick}>
          <h1>Your Upcoming Tests</h1>
          {folderOpened ? <FaFolderOpen /> : <FaFolderClosed />}
        </div>
        {folderOpened ?
          <div className="tests-folder">
            <div className="open-folder-initial-row">
              <div>
                <label>Test ID</label>
              </div>
              <div>
                <label>Purchased Date</label>
              </div>
              <div>
                <label>Begin</label>
              </div>
            </div>
            <div className="open-folder-tests-collection">
              {purchasedTestsExit ? purchasedTests.map((result) => {
                return (
                  <div className="open-folder-test-info">
                    <div>
                      <label>{result.id}</label>
                    </div>
                    <div>
                      <label>{result.purchasedDate}</label>
                    </div>
                    <div>
                      <p onClick={() => handleOnClick(result.id)}>Start test</p>
                    </div>
                  </div>
                )
              }) :
                <div className="open-folder-test-info">
                  <div>
                    <label>-</label>
                  </div>
                  <div>
                    <label>-</label>
                  </div>
                  <div>
                    <label>-</label>
                  </div>
                </div>
              }
            </div>
          </div>
          : null}
      </div>
    </div>
  );
};

export default PurchasedTests;
