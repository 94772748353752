import React, { forwardRef } from "react";
import "./why-take-the-ibis.css";
import { useNavigate } from "react-router-dom";
import { Paths } from '../../helpers';
import Poly from './images/poly.png';

const WhyTakeTheIBIS = forwardRef((props, ref) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(Paths.MyTests);
  }

  return (
      <div className="why-take-ibis" ref={ref}>
        <h1>Why take the IBIS</h1>
        <div className="information">
          <div className="reasons">
            <div className="reason-heading">
              <div className="reason-number">
                <h3>01</h3>
              </div>
              <div className="reason">
                <h3>Test your IB technicals</h3>
              </div>
            </div>
            <div className="reason-text">
              <p>The #1 way to test your investment banking technicals before you interview for internship / analysts positions. The test is designed by industry professionals for aspiring investment bankers</p>
            </div>
            <div className="reason-heading">
              <div className="reason-number">
                <h3>02</h3>
              </div>
              <div className="reason">
                <h3>Compare your results</h3>
              </div>
            </div>
            <div className="reason-text">
              <p>Compare your test score with thousands of other aspiring investment bankers to determine if you're ready for interviews. We tell you what percentile your score is and give you access to the IBIS leaderboards</p>
            </div>
            <div className="reason-heading">
              <div className="reason-number">
                <h3>03</h3>
              </div>
              <div className="reason">
                <h3>Detailed feedback report</h3>
              </div>
            </div>
            <div className="reason-text">
              <p>After taking the test you receive a feedback report that shows where your strengths and weaknesses are. This helps make your interview preparation more efficient</p>
            </div>
            <div>
              <button className="button-take-test" onClick={onClick}>Take a test</button>
            </div>
          </div>
          <div className="graphic">
            <img src={Poly} alt="Poly"/>
          </div>
        </div>
      </div>
  );
});

export default WhyTakeTheIBIS;
