import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./score-bar-chart.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: true,
    },
  },
  scales: {
    y: {
      min: 0,
      max: 100,
      ticks: {
        font: {
          weight: "bold",
        },
        color: "rgb(91, 88, 88)",
      },
    },
    x: {
      ticks: {
        font: {
          weight: "bold",
          size: "8%",
        },
        color: "rgb(91, 88, 88)",
      },
    },
  },
};

const ScoreBarChart = ({
  userAverage,
  globalAverage,
  username,
  country,
  countryAverage,
}) => {
  const labels = [username, country, "Global"];

  const data = {
    labels,
    datasets: [
      {
        data: [parseFloat(userAverage).toFixed(2), parseFloat(countryAverage).toFixed(2), parseFloat(globalAverage).toFixed(2)],
        backgroundColor: "#7d81e2",
        borderRadius: 5,
        width: 2,
      },
    ],
  };

  return (
    <div className="score-barchart">
      <Bar className="barchart" options={options} data={data} />
    </div>
  );
};

export default ScoreBarChart;
