import React, { useState } from "react";
import "./static-mode.css";
import { FaCheck, FaTimes } from "react-icons/fa";
import { FaFolderClosed } from "react-icons/fa6";
import { FaFolderOpen } from "react-icons/fa";

const StaticMode = ({ generalTableData, setInEditMode }) => {
  const [personalInformationOpen, setPersonalInformationOpen] = useState(true);

  const onEditClick = () => {
    setInEditMode(true);
  };

  const onPersonalInformationClick = () => {
    setPersonalInformationOpen(!personalInformationOpen);
  }

  return (
    <div className="static-mode-root">
      <div className="personal-information-folder">
        <div className="personal-information-header" onClick={onPersonalInformationClick}>
          <label>Personal Information</label>
          <label>{personalInformationOpen ? <FaFolderOpen /> : <FaFolderClosed />}</label>
        </div>
        {personalInformationOpen ?
          <div className="personal-information">
            <div className="static-mode-div">
              <label htmlFor="dob">Full name:</label>
              <label>{generalTableData.fullName ?? "-"}</label>
            </div>
            <div className="static-mode-div">
              <label htmlFor="dob">Date of Birth:</label>
              <label>{generalTableData.dob}</label>
            </div>
            <div className="static-mode-div">
              <label htmlFor="dob">Country of origin:</label>
              <label>{generalTableData.country ?? "-"}</label>
            </div>
            <div className="static-mode-div">
              <label htmlFor="dob">Phone number:</label>
              <label>{generalTableData.phoneNumber ?? "-"}</label>
            </div>
          </div>
          : null}
      </div>
      <div className="static-mode-consents-div">
        {generalTableData.allowSharingOfInformation ? (
          <FaCheck size={24} className="static-mode-consents-icon" />
        ) : (
          <FaTimes size={24} className="static-mode-consents-icon" />
        )}
        I allow IBIS to share my results and contact information to IBIS
        recruiters.
      </div>
      <div className="static-mode-consents-div">
        {generalTableData.allowSendingOfEmails ? (
          <FaCheck size={24} className="static-mode-consents-icon" />
        ) : (
          <FaTimes size={24} className="static-mode-consents-icon" />
        )}
        I allow IBIS to send me emails about updates for new tests and other
        things.
      </div>
      <div className="static-mode-bottom">
        <button onClick={onEditClick}>Edit</button>
      </div>
    </div>
  );
};

export default StaticMode;
