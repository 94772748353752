import React from "react";
import { useNavigate } from "react-router-dom";
import { PiExamBold } from "react-icons/pi";
import { FaPencilAlt, FaMedal } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { MdAutoGraph } from "react-icons/md";
import { Paths } from "../../helpers/constants";
import { FaCheck, FaTimes } from "react-icons/fa";
import "./how-it-works.css";

const HowItWorks = () => {
  const navigate = useNavigate();

  const itemOnClick = (path) => {
    navigate(`${path}`);
  };

  const navigateToBookATest = () => {
    navigate(Paths.BookATest);
  }

  const navigateToMyTests = (testId) => {
    const params = new URLSearchParams({
      testId: testId,
    });

    navigate(`${Paths.MyTests}?${params.toString()}`);
  }

  return (
    <section className="how-it-works-root">
      <div className="how-it-works">
        <h1>Start the IBIS100 test in 5 easy steps</h1>
        <div className="how-it-works-div">
          <div className="item" onClick={() => itemOnClick(Paths.BookATest)}>
            <div>
              <h3>
                1. Book a Test {<PiExamBold className="icon" color="gold" />}
              </h3>
            </div>
            <p>
              Securely book your exam through our purchase portal, powered by
              Stripe.
            </p>
          </div>
          <div className="item" onClick={() => itemOnClick(Paths.MyTests)}>
            <div>
              <h3 onClick={() => itemOnClick(Paths.MyTests)}>
                2. Access Your Tests {<CgWebsite className="icon" color="grey" />}
              </h3>
            </div>
            <p>
              View and access exams you've purchased as well as previously
              completed exams.
            </p>
          </div>
          <div className="item" onClick={() => itemOnClick(Paths.MyTests)}>
            <div>
              <h3>
                3. Take the Exam {<FaPencilAlt className="icon" color="blue" />}
              </h3>
            </div>
            <p>
              Go to "My Tests", open "Your Upcoming Tests", and select "Start test"
              on the test you wish to begin.
            </p>
          </div>
          <div className="item" onClick={() => itemOnClick(Paths.Home)}>
            <div>
              <h3>
                4. Receive Results and Certificate{" "}
                {<FaMedal className="icon" color="yellow" />}
              </h3>
            </div>
            <p>
              After finishing your exam, you'll receive a personal performance
              review, your score, and a certificate via email.
            </p>
          </div>
          <div className="item" onClick={() => itemOnClick(Paths.Results)}>
            <div>
              <h3>
                5. Compare and Compete{" "}
                {<MdAutoGraph className="icon" color="green" />}
              </h3>
            </div>
            <p>
              Track your progress and compare your results with others on our
              leaderboard, showcasing your previously taken exams.
            </p>
          </div>
        </div>
      </div>
      <div className="test-information-breakdown-root">
        <div className="test-information-breakdown">
          <div className="test-information-header">
            <h1>IBIS100</h1>
          </div>
          <div className="tib-stat">
            <h3 style={{ fontWeight: 100 }}>Price:</h3>
            <h3>$20</h3>
          </div>
          <div className="tib-stat">
            <h3 style={{ fontWeight: 100 }}>Number of questions:</h3>
            <h3>100</h3>
          </div>
          <div className="tib-stat">
            <h3 style={{ fontWeight: 100 }}>Test length:</h3>
            <h3>100 minutes</h3>
          </div>
          <div className="tib-stat">
            <h3 style={{ fontWeight: 100 }}>Email of completion report:</h3>
            <FaCheck className="tib-stat-mark" style={{ alignSelf: 'center', color: 'blue' }} />
          </div>
          <div className="tib-stat">
            <h3 style={{ fontWeight: 100 }}>Certification available:</h3>
            <FaCheck className="tib-stat-mark" style={{ alignSelf: 'center', color: 'blue' }} />
          </div>
          <div className="tib-stat-button">
            <button onClick={navigateToBookATest}>Book a test</button>
            <button onClick={() => navigateToMyTests('IBIS100')}>Take a test</button>
          </div>
        </div>
        <div className="test-information-breakdown">
          <div className="test-information-header">
            <h1>Practice test</h1>
          </div>
          <div className="tib-stat">
            <h3 style={{ fontWeight: 100 }}>Price:</h3>
            <h3>Free</h3>
          </div>
          <div className="tib-stat">
            <h3 style={{ fontWeight: 100 }}>Number of questions:</h3>
            <h3>6</h3>
          </div>
          <div className="tib-stat">
            <h3 style={{ fontWeight: 100 }}>Test length:</h3>
            <h3>6 minutes</h3>
          </div>
          <div className="tib-stat">
            <h3 style={{ fontWeight: 100 }}>Email of completion report:</h3>
            <FaTimes className="tib-stat-mark" style={{ alignSelf: 'center', color: 'red' }} />
          </div>
          <div className="tib-stat">
            <h3 style={{ fontWeight: 100 }}>Certification available:</h3>
            <FaTimes className="tib-stat-mark" style={{ alignSelf: 'center', color: 'red' }} />
          </div>
          <div className="tib-stat-button">
            <button onClick={() => navigateToMyTests('Practice test')}>Take a test</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
