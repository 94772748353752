import React, { useEffect, useState, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { fetchRequest, getCurrentDate } from "../../helpers";
import Spinner from "../../spinner/spinner";
import "./checkout-form.css";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Paths } from '../../helpers';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Alert, AlertTitle } from "@mui/material";

const stripePromise = loadStripe("pk_test_51PUSfd2MOLtuggfmxe6l0yvHzlU2HgMQUwcfmNIBClBuFeGLAHmEGdp1L7TVkY0wIuoc3FlLKehIFERSud0iiQbS00cHmuXRAC");

const CheckoutForm = ({ 
    idToken, 
    setShowCheckoutForm, 
    setInError, 
    sessionId, 
    setSessionId, 
    paymentShowingDivRef, 
    setScrollToPaymentView 
}) => {
    const navigate = useNavigate();

    const [clientSecret, setClientSecret] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [showPurchaseComplete, setShowPurchaseComplete] = useState(false);

    const [timeout, setTimeoutState] = useState(0);
    const countdownRef = useRef(40); 
    const countdownIntervalRef = useRef(null);

    useEffect(() => {
        setScrollToPaymentView(true);
    }, [idToken]);

    useEffect(() => {
        const getSessionStatus = async () => {
            try {
                const response = await fetchRequest(idToken, `sessionStatus?session_id=${sessionId}`, 'POST');

                if (response.status !== 200) {
                    setInError(true);
                }

                const data = await response.json();

                if (data.status === 'complete') {
                    const purchasedDate = getCurrentDate();

                    const response = await fetchRequest(idToken, "purchaseExam", "POST", {
                        testId: 'IBIS100',
                        purchasedDate: purchasedDate,
                    });

                    if (response.status === 200) {
                        console.log('resetting stuff');
                        setSessionId(null);
                        setShowPurchaseComplete(true);
                    } else {
                        setInError(true);
                    }
                } else {
                    setInError(true);
                }
            } catch (error) {
                console.error("Error fetching session status:", error);
            } finally {
                setIsLoading(false);
            }
        }

        const fetchCheckoutSession = async () => {
            try {
                const response = await fetchRequest(idToken, "createCheckoutSession", "GET");
                if (response.status !== 200) {
                    navigate(Paths.BookATest);
                    setInError(true);
                }
                const data = await response.json();

                setClientSecret(data.clientSecret);
            } catch (error) {
                console.error("Error fetching checkout session:", error);
            } finally {
                setIsLoading(false);
            }
        };

        if (sessionId) {
            getSessionStatus();
        } else {
            fetchCheckoutSession();
        }

        if (showPurchaseComplete) {
            invokeNavigation();
        }
    }, [idToken, sessionId]);

    const options = { clientSecret };

    const backOnClick = () => {
        if (showPurchaseComplete) {
            navigate(Paths.BookATest);
            window.location.reload();
        }
        setShowCheckoutForm(false);
        setScrollToPaymentView(false);
    }

    const invokeNavigation = () => {
        if (countdownIntervalRef.current) return;

        countdownRef.current = 1000;
        setTimeoutState(countdownRef.current);

        countdownIntervalRef.current = setInterval(() => {
            countdownRef.current -= 1;
            setTimeoutState(countdownRef.current);

            if (countdownRef.current <= 0) {
                clearInterval(countdownIntervalRef.current);
                countdownIntervalRef.current = null;
                navigate(Paths.Home);
                window.location.reload();
            }
        }, 1000);
    };

    return (
        <div className="checkout-root">
            <div className="checkout" ref={paymentShowingDivRef} >
                <div className="checkout-top">
                    <FaTimes className="checkout-back-icon" onClick={backOnClick} />
                </div>
                {showPurchaseComplete ?

                    <div className="payment-complete">
                        <Alert
                            icon={<CheckCircleIcon fontSize="inherit" />}
                            severity="success"
                            className="payment-alert"
                        >
                            <AlertTitle className="payment-alert-title">Payment Confirmed</AlertTitle>
                            <p>Thank you! Your payment has been successfully processed. A confirmation email will be sent shortly. For any enquiries, please email admin@ibis100.com</p>
                            <p>The page will close in: <strong>{timeout}</strong></p>
                        </Alert>
                        {invokeNavigation()}
                    </div> :
                    <div className="checkout-form">
                        {isLoading ?
                            <Spinner />
                            :
                            !showPurchaseComplete ?
                                <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                                    <EmbeddedCheckout />
                                </EmbeddedCheckoutProvider>
                                :
                                <div className="payment-complete">
                                    <Alert
                                        icon={<CheckCircleIcon fontSize="inherit" />}
                                        severity="success"
                                        style={{ maxWidth: "500px", margin: "auto" }}
                                    >
                                        <AlertTitle>Payment Confirmed</AlertTitle>
                                        Thank you! Your payment has been successfully processed.
                                    </Alert>
                                    {invokeNavigation()}
                                </div>
                        }
                    </div>
                }
            </div>

            <div className="checkout-bottom">
                <p>
                    We use <strong>Stripe</strong>, a leading payment processing platform trusted by millions of businesses worldwide. Stripe is certified to the highest industry standards and uses state-of-the-art encryption to keep your information secure.
                </p>
                <p>
                    All transactions are securely processed, and your payment details are not stored on our servers. You can proceed with confidence knowing your payment is handled with the highest level of security.
                </p>
            </div>
        </div>
    );
};

export default CheckoutForm;