import React, { useState } from "react";
import "./register-user.css";
import { getNames } from "country-list";
import Spinner from "../spinner/spinner";
import { fetchRequest } from "../helpers/fetch-function";
import ErrorFlag from "../error-flag/error-flag";
import { CiLogout } from "react-icons/ci";

const countries = getNames();

const RegisterUser = ({ setInError, inError, idToken, signOut }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countries); // State for filtered countries
  const [isChecked, setIsChecked] = useState(false);
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [labelStyle, setLabelStyle] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setLabelStyle({});
    }
  };

  const handleEmailCheckboxChange = (event) => {
    setIsEmailChecked(event.target.checked);
    if (event.target.checked) {
      setLabelStyle({});
    }
  };

  const handleLogoutClick = () => {
    if (signOut) {
      signOut();
    }
  };

  const handleCountryInputChange = (event) => {
    const input = event.target.value;
    setSelectedCountry(input);
    setShowDropdown(true);

    const filtered = countries.filter((country) =>
      country.toLowerCase().startsWith(input.toLowerCase())
    );
    setFilteredCountries(filtered);
  };

  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false);
  };

  const handleButtonClick = async () => {
    const dob = document.getElementById("dob").value;
    const country = selectedCountry;
    if (!countries.includes(country)) {
      setShowWarning(true);
    } else if (!isChecked || dob === undefined || country === undefined) {
      setShowWarning(true);
    } else {
      try {
        setIsLoading(true);
        const response = await fetchRequest(idToken, "registerUser", "POST", {
          dob: dob,
          country: country,
        });
        // const response = {
        //   status: 200,
        // };

        if (response.status === 200) {
          window.location.reload();
        } else {
          setInError(true);
        }
      } catch (error) {
        console.log(error);
        setInError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="register-user">
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="register-div">
          <div className="register-component">
            <div className="register-heading">
              {inError ? <ErrorFlag setInError={setInError} /> : null}
              <h1>Register</h1>
            </div>
            <div className="register-inputs">
              <div>
                <label htmlFor="dob">
                  <span className="star">*</span>Date of Birth:
                </label>
                <input type="date" id="dob" name="dob" required />
              </div>
              <div>
                <label htmlFor="country">
                  <span className="star">*</span>Country of origin:
                </label>
                <input
                  id="country-selected"
                  type="text"
                  autocomplete="off"
                  value={selectedCountry}
                  onChange={handleCountryInputChange}
                  onClick={() => setShowDropdown(!showDropdown)}
                  placeholder="Select a country"
                />
                {showDropdown && (
                  <ul className="country-dropdown">
                    {filteredCountries.map((country) => (
                      <li
                        key={country}
                        onClick={() => handleSelectCountry(country)}
                      >
                        {country}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="consents">
              <div>
                <label className="star">*</label>
                <input
                  type="checkbox"
                  id="uniqueCheckboxId1"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="consents-label"
                  htmlFor="uniqueCheckboxId1"
                  style={labelStyle}
                >
                  I consent to IBIS using my information for results and rankings.
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="uniqueCheckboxId2"
                  checked={isEmailChecked}
                  onChange={handleEmailCheckboxChange}
                />
                <label
                  className="consents-label"
                  htmlFor="uniqueCheckboxId2"
                  style={labelStyle}
                >
                  I want IBIS to email me about more information on new exams,
                  etc.
                </label>
              </div>
            </div>
            <div className="register-button-div">
              <button onClick={handleButtonClick}>Submit</button>
            </div>
            {showWarning ? (
              <div className="register-warning">
                <label>*Please fill in all required fields with valid values.</label>
              </div>
            ) : null}
          </div>
        </div>
      )}
      <div className="register-logout-div">
        <CiLogout onClick={handleLogoutClick} className="register-logout" />
      </div>
    </div>
  );
};

export default RegisterUser;
